import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { Routes, RouterModule } from '@angular/router';
import { ContactComponent } from './contact/contact.component';
import { AboutComponent } from './about/about.component';
import { PricingComponent } from './pricing/pricing.component';
import { BlogComponent } from './blog/blog.component';
import { ProcessDescriptionComponent } from './process-description/process-description.component';

const routes: Routes = [
  { path: '', redirectTo: 'strona-glowna', pathMatch: 'full' },
  { path: 'strona-glowna', component: HomeComponent },
  { path: 'o-nas', component: AboutComponent },
  { path: 'ozon-technologia-proces', component: ProcessDescriptionComponent },
  { path: 'cennik', component: PricingComponent },
  { path: 'kontakt', component: ContactComponent },
  { path: 'blog', component: BlogComponent },
  { path: '**', redirectTo: 'strona-glowna'}
];

@NgModule({
  declarations: [
    HomeComponent,
    ContactComponent,
    AboutComponent,
    PricingComponent,
    BlogComponent,
    ProcessDescriptionComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
  ],
  exports: [
    HomeComponent,
    ContactComponent,
    AboutComponent,
    PricingComponent,
    BlogComponent,
    ProcessDescriptionComponent
  ]
})
export class SiteModule { }
