<section class="site-container">
  <header>
    <div class="logo-container">
      <a [routerLink]="[ 'strona-glowna' ]" routerLinkActive="active"><img src="../assets/name-logo.png" alt="Logo"
          class="logo"></a>

    </div>
    <div role="navigation">
      <nav class="main-nav mobile-hide" data-content-field="navigation">
        <ul>
          <li>
            <a [routerLink]="[ 'strona-glowna' ]" routerLinkActive="active">Strona Główna</a>
          </li>
          <li>
            <a [routerLink]="[ 'o-nas' ]" routerLinkActive="active">O Nas</a>
          </li>
          <li class="page-collection active-link">
            <a [routerLink]="[ 'ozon-technologia-proces' ]" routerLinkActive="active">Ozon/technologia/proces</a>
          </li>
          <li class="gallery-collection">
            <a [routerLink]="[ 'cennik' ]" routerLinkActive="active">Cennik</a>
          </li>
          <li>
            <a [routerLink]="[ 'kontakt' ]" routerLinkActive="active">Kontakt</a>
          </li>
          <li>
            <a [routerLink]="[ 'blog' ]" routerLinkActive="active">Blog</a>
          </li>
        </ul>
      </nav>
      <nav class="main-nav mobile-show" data-content-field="navigation">
        <button type="button" (click)="menuTrigger()"><i class="material-icons">menu</i></button>
        <ul class="menu" *ngIf="menuVisible">
          <li>
            <a [routerLink]="[ 'strona-glowna' ]" routerLinkActive="active" (click)="menuTrigger()">Strona Główna</a>
          </li>
          <li>
            <a [routerLink]="[ 'o-nas' ]" routerLinkActive="active" (click)="menuTrigger()">O Nas</a>
          </li>
          <li class="page-collection active-link">
            <a [routerLink]="[ 'ozon-technologia-proces' ]" routerLinkActive="active"
              (click)="menuTrigger()">Ozon/technologia/proces</a>
          </li>
          <li class="gallery-collection">
            <a [routerLink]="[ 'cennik' ]" routerLinkActive="active" (click)="menuTrigger()">Cennik</a>
          </li>
          <li>
            <a [routerLink]="[ 'kontakt' ]" routerLinkActive="active" (click)="menuTrigger()">Kontakt</a>
          </li>
          <li>
            <a [routerLink]="[ 'blog' ]" routerLinkActive="active" (click)="menuTrigger()">Blog</a>
          </li>
        </ul>
      </nav>
    </div>
  </header>
  <router-outlet></router-outlet>
</section>
<footer>
  <p><strong>Odwiedź nas na:</strong></p>
  <a href="https://www.facebook.com/OxyCorp-112211957061148/" class="facebook"><img
      src="../assets/f_logo_RGB-Black_58.png"></a>
  <a href="https://www.instagram.com/oxycorp/?igshid=1oym5rmx54jav&fbclid=IwAR0Dj1oC3Y7QKNbMuU5x0wzLVkVFPKhnJhtqpCuRBpHl7kLy4dusgSDsMwc"
    class="instagram"><img src="../assets/glyph-logo_May2016.png"></a>
</footer>