<h1>Definicja dezynfekcji: </h1>
<p>Jest to proces mający na celu oczyszczenie powierzchni i przestrzeni do tego stopnia, że staje się ona bezpieczna dla
    człowieka – postępowanie, którego efektem jest zniszczenie drobnoustrojów i ich przetrwalników. Oznacza to, że po
    wykonanej usłudze dezynfekcji pomieszczenie nią objęte staje się niegroźne dla zdrowia i życia człowieka pod
    względem obecności w nim wirusów, bakterii, patogenów, etc. </p>
<div class="img__container">
    <img src="../../../assets/ozonator.jpg" class="img--max-width"
        alt="ozonator, dezynfekcja, ozonowanie, oxycorp, wirus, coronavirus, koronawirus">
    <p class="" class="img__description">Ozonatory ustawiane są w najwyższych punktach pomieszczeń. Dzięki temu ozon,
        który jest cięższy od powietrza, najpierw zdezynfekuje górną część pomieszczenia, po czym dostanie się również
        na niższy poziom. </p>
</div>
<h1>Przewaga nad chlorem: </h1>
<p>Ozon w odróżnieniu od chloru wnika w każdą składową komórki, czyli w enzymy, białka, RNA oraz DNA, pozwala to
    skutecznie unieszkodliwić komórkę, przyspieszyć proces jej rozkładu. Natomiast chlor „zajmuje” się tylko enzymami w
    komórce. Szacuje się, że proces dezynfekcji ozonem jest w tym momencie najskuteczniejszą metodą odkażania
    powierzchni. Ozon ma zapach, który naturalnie występuje po burzy, podczas której były widoczne wyładowania
    elektryczne – w przeciwieństwie do chloru i jego skrajnie nieprzyjemnego zapachu. </p>
<div class="img__container">
    <img src="../../../assets/zabezpieczanie_pomieszczenia.jpg" class="img--max-width"
        alt="zabezpieczanie, bezpieczeństwo, ozonowanie, przygotowanie ozonowania, oxycorp, wirus">
    <p class="" class="img__description">Każde z pomieszczeń jest zabezpieczane w każdych wrażliwych punktach przed
        rozpoczęciem ozonowania. Dzięki temu ozon nie wydobywa się z pomieszczenia, a dezynfekcja jest bardziej
        dokładna. </p>
</div>
<h1>Z racji obcowania z gazem niebezpiecznym też dla ludzi, nasze działania są objęte następującą procedurą: </h1>
<ul data-rte-list="default">
    <li>
        <p class="" style="white-space:pre-wrap;">Poznanie kubatury netto obiektu oraz stopnia uszczelnienia </p>
    </li>
    <li>
        <p class="" style="white-space:pre-wrap;">Wyliczenie potrzebnego czasu ozonowania oraz wietrzenia obiektu </p>
    </li>
    <li>
        <p class="" style="white-space:pre-wrap;">Poznanie aktualnego średniego składu powietrza w budynku </p>
    </li>
    <li>
        <p class="" style="white-space:pre-wrap;">Ustalenie punktów pomiaru stężenia ozonu w powietrzu </p>
    </li>
    <li>
        <p class="" style="white-space:pre-wrap;">Określenie punktów maksymalnego wietrzenia oraz uszczelnienie tych
            miejsc za pomocą folii i taśm przemysłowych </p>
    </li>
    <li>
        <p class="" style="white-space:pre-wrap;">Ostateczny sprawdzian bezpieczeństwa osób w obiekcie – zaproszenie
            wszystkich do opuszczenia ozonowanej przestrzeni </p>
    </li>
    <li>
        <p class="" style="white-space:pre-wrap;">Rozpoczęcie procedury ozonowania według potrzebnego czasu i stężenia
            na podstawie obliczeń i określonego profilu zabiegu </p>
    </li>
    <li>
        <p class="" style="white-space:pre-wrap;">Po odpowiednim czasie – sprawdzenie stężenia ozonu w powietrzu </p>
    </li>
    <li>
        <p class="" style="white-space:pre-wrap;">Rozpoczęcie procesu wietrzenia po utrzymaniu ozonu w odpowiednim
            stężeniu przez wymagany czas </p>
    </li>
    <li>
        <p class="" style="white-space:pre-wrap;">Zakończenie procesu wietrzenia i końcowe sprawdzenie stężenia ozonu
        </p>
    </li>
    <li>
        <p class="" style="white-space:pre-wrap;">Uprzątnięcie całości sprzętu oraz usunięcie uszczelnień </p>
    </li>
</ul>