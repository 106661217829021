import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-process-description',
  templateUrl: './process-description.component.html',
  styleUrls: ['./process-description.component.scss']
})
export class ProcessDescriptionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
