<h1>Definiujemy poziom bezpieczeństwa.</h1>
<div>
    <div>
        <div>
            <div>
                <p style="white-space:pre-wrap;">Przeprowadzamy bezpieczną oraz ekologiczną dezynfekcję przestrzeni
                    publicznych i szeroko pojętych obiektów. Skuteczność osiągamy stosując najlepsze obecnie metody,
                    opierające się na utlenianiu bakterii, wirusów, patogenów, etc. od wewnątrz. Przeprowadzając
                    dezynfekcję metodą oksydacji nie tylko zwiększamy bezpieczeństwo osób przebywających na terenie
                    obiektu, ale też znacząco ograniczamy rozwój mikroorganizmów w przyszłości, tworząc na
                    powierzchniach warunki skrajnie nieprzyjazne dla ich powstawania i rozprzestrzeniania się. </p>
                <p>Cząstki ozonu tworzymy za pomocą wyładowań koronowych – uzyskując jego odpowiednie stężenie sprawnie
                    pozbywamy się z powierzchni i przestrzeni obiektu objętego usługą wszystkich niewidocznych gołym
                    okiem zagrożeń dla życia i zdrowia człowieka. </p>
            </div>
            <div class="img__container">
                <img src="../../../assets/maski_ochronne.jpg" class="img--max-width"
                    alt="maski ochronne, filtry węglowe, ozonowanie, wirus, oxycorp">
                <p class="" class="img__description">Bezpieczeństwo zawsze stawiamy na pierwszym miejscu, dlatego nasi
                    pracownicy używają wysokiej jakości sprzętu, między innymi masek ochronnych z filtrami węglowymi.
                </p>
            </div>
        </div>
    </div>
</div>