<h1>Kontakt:</h1>
<section class="management">
    <div class="management__container">
        <img src="../../../assets/karol.pietrewicz_optimized.jpg" class="img--portrait"
                    alt="Karol Pietrewicz">
        <p>Karol Pietrewicz</p>
        <p><strong>Partner Zarządzający</strong></p>
        <p>tel: +48  665  122  383</p>
        <p>e-mail: <a href="mailto:karol.pietrewicz@oxycorp.pl">karol.pietrewicz@oxycorp.pl</a></p>
    </div>
    <div class="management__container">
        <img src="../../../assets/jan.smolinski_optimized.jpg" class="img--portrait"
                    alt="Jan Smoliński">
        <p>Jan Smoliński</p>
        <p><strong>Partner Zarządzający</strong></p>
        <p>tel: +48  694  823  457</p>
        <p>e-mail: <a href="mailto:jan.smolinski@oxycorp.pl">jan.smolinski@oxycorp.pl</a></p>
    </div>
</section>
<br/>
<p>Adres firmy:</p>
<p>ul. Żubrowa 25</p>
<p>01-978 Warszawa</p>
<p>e-mail: <a href="mailto:biuro@oxycorp.pl">biuro@oxycorp.pl</a></p>