<h1>Cennik usług z zakresu wykorzystania ozonu: </h1>
<div>
    <p>Każdej wyceny dokonujemy na podstawie łącznej kubatury netto obiektów klienta. Progi cenowe kształtują się
        następująco: </p>
    <ul data-rte-list="default">
        <li>
            <p class="" style="white-space:pre-wrap;font-size: 18px;">Łączna kubatura – do 12 000 m³ – <strong>1zł netto/m³</strong> </p>
        </li>
        <li>
            <p class="" style="white-space:pre-wrap;font-size: 18px;">Łączna kubatura – do 18 000 m³ – <strong>0,95zł netto/m³</strong> </p>
        </li>
        <li>
            <p class="" style="white-space:pre-wrap;font-size: 18px;">Łączna kubatura – powyżej 18 000 m³ – <strong>0,93zł netto/m³</strong> </p>
        </li>
    </ul>
    <div class="img__container">
        <img src="../../../assets/miernik_stezenia.jpg" class="img--max-width"
            alt="miernik stężenia, ozonowanie, oxycorp, stężenie ozonu, wirus">
        <p class="" class="img__description">Korzystamy z najwyższej półki sprzętu od sprawdzonych producentów.
            Zapewniamy wysoką jakość przeprowadzanych przez nas usług oraz przede wszystkim bezpieczeństwo. </p>
    </div>
</div>
