<h1>Kim jesteśmy?</h1>
<p>
    Startup współtworzony jest przez grupę studentów warszawskich uczelni, zarządzany przez dwójkę długoletnich
    przyjaciół. Jako ludzie młodzi stawiamy w pracy na jak największy rozwój kompetencji i zdobywanie nowych
    umiejętności. Jest to wiek, w którym kreatywność działa na największych obrotach, stąd też potrzeba wprowadzenia
    innowacyjności – motor napędowy stworzenia OxyCorp. Zainteresowanie chemią sprawiło, że postanowiliśmy rozpocząć
    projekt związany z ozonowaniem, aby zapewnić ludziom ekologiczne i bezpieczne możliwości dezynfekcji.
</p>
<h1>Dlaczego ozon?</h1>
<p>Ozon jest reaktywną formą tlenu, naturalnie występuje w stratosferze, gdzie na wysokości 32 km formuje ozonosferę,
    która pełni bardzo ważną rolę w utrzymaniu życia na Ziemi – przechwytuje większość promieniowania ultrafioletowego.

    Jego reaktywność przejawia się między innymi tym, że jest świetnym utleniaczem – jest on w stanie przenikać błony
    komórkowe oraz utlenić wszelkie niezbędne do życia wirusa lub bakterii składniki. Mowa tutaj o wnikaniu w strukturę
    komórki do tego stopnia, że utlenione zostają enzymy, białka, RNA i DNA komórki. Proces tak dogłębnego wnikania w
    strukturę komórki jest nazywany „lizą”. Wybierając metodę dezynfekcji skupiliśmy się nie tylko na jej skuteczności,
    ale też na byciu ekologicznym, dlatego wybór padł właśnie na ozon. </p>

<div class="img__container">
    <img src="../../../assets/pomiar_stezenia.jpg" class="img--max-width"
        alt="pomiar stężenia, ozonowanie, oxycorp, wirus">
    <p class="" class="img__description">Po zakończeniu ozonowania przynajmniej przez 30 minut sprawdzamy stężenie
        ozonu w pomieszczeniu.</p>
</div>